import React, { useEffect } from "react";
import { BrowserRouter, Route, Link, Switch } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen.js";
import PrivateRoute from "./components/PrivateRoute.js";
import PlaylistScreen from "./screens/PlaylistScreen.js";
import CreateListScreen from "./screens/CreateListScreen.js";
import ProductScreen from "./screens/ProductScreen.js";
import SettingsScreen from "./screens/SettingsScreen.js";
import RegisterScreen from "./screens/RegisterScreen.js";
import UserScreen from "./screens/UserScreen.js";

import LatestReviewsScreen from "./screens/LatestReviewsScreen.js";

import SigninScreen from "./screens/SigninScreen.js";
/* import AdminRoute from "./components/AdminRoute.js";  */
import BrowseVideosScreen from "./screens/BrowseVideosScreen.js";
import SearchBox from "./components/SearchBox.js";
import SearchScreen from "./screens/SearchScreen.js";
import ListScreen from "./screens/ListScreen.js";
import ListsScreen from "./screens/ListsScreen.js";

import NotFoundPage from "./screens/NotFoundPage.js";

import ContributionSentScreen from "./screens/ContributionSentScreen.js";
import ChannelSentScreen from "./screens/ChannelSentScreen.js";
import { useMediaQuery } from "react-responsive";
import ReactGA from "react-ga4";
import SignedInScreen from "./screens/SignedInScreen.js";
import SearchResultsScreen from "./screens/SearchResultsScreen.js";
import { AuthProvider } from "./AuthContext.js";
import LoginHeader from "./components/LoginHeader";
import LoginHeaderMobile from "./components/LoginHeaderMobile";

import ForgotPassword from "./screens/ForgotPassword.js";
import AddChannelScreen from "./screens/AddChannelScreen.js";
import TopScreen from "./screens/TopScreen.js";

function App() {
  /* const dispatch = useDispatch();

  /*
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const userId = userInfo ? userInfo._id : "";

  const listPlaylistUser = useSelector((state) => state.listPlaylist);
  const { playlist2s } = listPlaylistUser;  */

  /*
  useEffect(() => {
    dispatch(listPlaylist(userId));
  }, [dispatch]);  */

  /* const [nbPlaylist, setNbPlaylist] = useState("");

  const nbItemsPlaylist = new Set(playlist2s).size;  */

  /*
  useEffect(() => {
    setNbPlaylist(new Set(playlist2s).size);
  }, [dispatch, nbItemsPlaylist, playlist2s]);  */

  const refreshPage = () => {
    window.location.reload();
  };

  const isMobile = !useMediaQuery({
    query: "(min-width: 1070px)",
  });

  const largeScreen = useMediaQuery({
    query: "(min-width: 1300px)",
  });

  function toggleOffer() {
    let toggleButton = document.getElementById("checkboxMenu");
    toggleButton.checked = false;
  }

  function toggleOfferOpen() {
    let toggleButton = document.getElementById("checkboxMenu");
    toggleButton.checked = true;
  }

  useEffect(() => {
    ReactGA.initialize("G-X33N4CGW59");
    ReactGA.send(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <div className="grid-container">
          <header className="row">
            <div>
              <Link className="brand" to="/">
                <img
                  className="logo"
                  src="/images/FavoreeLogo3.png"
                  alt="Favoree Logo"
                  height="34"
                  width="132"
                />
                <img
                  className="beta"
                  src="/images/beta.png"
                  alt="Beta version"
                  height="25"
                  width="50"
                />
              </Link>
            </div>
            {isMobile && (
              <i
                className="fa-solid fa-magnifying-glass"
                onClick={toggleOfferOpen}
              ></i>
            )}
            {!isMobile && (
              <div>
                <Route
                  render={({ history }) => (
                    <SearchBox history={history}></SearchBox>
                  )}
                  /* onClick={toggleOffer}  */
                ></Route>
              </div>
            )}
            {!isMobile ? (
              <nav role="navigation">
                <div className="menuBar">
                  <Link to="/search">Channels</Link>
                  <Link to="/latest-reviews">Reviews</Link>

                  {largeScreen && <Link to="/lists">Lists</Link>}

                  <a
                    target="_blank"
                    href="https://discord.gg/zfudkGhxzH"
                    aria-label="Go to Favoree's Discord"
                  >
                    <i class="fa-brands fa-discord"></i>
                  </a>
                  <LoginHeader></LoginHeader>
                </div>{" "}
              </nav>
            ) : (
              <nav role="navigation">
                <div id="menuToggleHam">
                  <input type="checkbox" id="checkboxMenu" />
                  <span></span>
                  <span></span>
                  <span></span>
                  <ul id="menuHam">
                    <div className="space"></div>

                    <div>
                      <Route
                        render={({ history }) => (
                          <SearchBox history={history}></SearchBox>
                        )}
                      ></Route>
                    </div>

                    <Link to="/search" onClick={toggleOffer}>
                      <li>
                        <i class="fa-solid fa-play"></i> Channels{" "}
                      </li>
                    </Link>
                    <Link to="/lists" onClick={toggleOffer}>
                      <li>
                        <i class="fa-solid fa-rectangle-list"></i> Lists{" "}
                      </li>
                    </Link>
                    <Link to="/latest-reviews" onClick={toggleOffer}>
                      <li>
                        <i class="fa-solid fa-star"></i> Reviews
                      </li>
                    </Link>

                    <a
                      href="/favorites"
                      onClick={() => {
                        refreshPage();
                        toggleOffer();
                      }}
                    >
                      <li>
                        <i class="fa-solid fa-heart"></i> My favorites
                      </li>
                    </a>
                    <Link to="/add-channel" onClick={toggleOffer}>
                      <li>
                        <i class="fa-solid fa-plus"></i> Add a channel
                      </li>
                    </Link>
                    <LoginHeaderMobile
                      toggleOffer={toggleOffer}
                    ></LoginHeaderMobile>

                    <div className="space"> </div>
                    <div className="alignHorizontal">
                      <a
                        target="_blank"
                        href="https://discord.gg/zfudkGhxzH"
                        aria-label="Go to Favoree's Discord"
                      >
                        <i class="fa-brands fa-discord"></i>
                      </a>
                    </div>
                    <div className="alignVertical" id="getData">
                      <div className="space"> </div>
                      <div className="space"></div>

                      <text>
                        We are building the largest YouTube channel database
                      </text>
                      <div className="space"> </div>

                      <a
                        href="https://blog.favoree.io/data-services-favoree/?ref=burgerMobile"
                        className="giveReview"
                        target="_blank"
                      >
                        📊 Get data and insights
                      </a>
                    </div>
                  </ul>
                </div>
              </nav>
            )}
          </header>
          <main>
            <Switch>
              <PrivateRoute
                path="/favorites/:id?"
                component={PlaylistScreen}
              ></PrivateRoute>
              <Route
                path="/channel/:name-:id"
                component={ProductScreen}
                exact
              ></Route>

              <Route path="/signin" component={SigninScreen}></Route>
              <Route path="/forgot-password" component={ForgotPassword}></Route>

              <Route path="/register" component={RegisterScreen}></Route>
              <PrivateRoute
                path="/signedin"
                component={SignedInScreen}
              ></PrivateRoute>
              <Route
                path="/submit-content"
                component={AddChannelScreen}
              ></Route>
              <Route path="/add-channel" component={AddChannelScreen}></Route>

              <Route path="/contact" component={AddChannelScreen} exact></Route>
              <Route
                path="/search/name/:name?/language/:language"
                component={SearchScreen}
                exact
              ></Route>
              <Route path="/top/:top" component={TopScreen} exact></Route>
              <Route
                path="/search/category/:category"
                component={SearchScreen}
                exact
              ></Route>
              <Route
                path="/search/results/:query"
                component={SearchResultsScreen}
                exact
              ></Route>
              <Route
                path="/search/language/:language"
                component={SearchScreen}
                exact
              ></Route>
              <Route path="/search" component={SearchScreen} exact></Route>
              <Route
                path="/contribution-submitted"
                component={ContributionSentScreen}
                exact
              ></Route>
              <Route
                path="/browse-videos"
                component={BrowseVideosScreen}
                exact
              ></Route>
              <Route
                path="/browse-videos/:topic"
                component={BrowseVideosScreen}
                exact
              ></Route>
              <Route
                path="/channel-submitted"
                component={ChannelSentScreen}
                exact
              ></Route>

              <Route
                path="/search/allTopics/:allTopics"
                component={SearchScreen}
                exact
              ></Route>
              <Route
                path="/search/mood/:mood"
                component={SearchScreen}
                exact
              ></Route>
              <Route
                path="/search/level/:level"
                component={SearchScreen}
                exact
              ></Route>
              <Route
                path="/search/duration/:duration"
                component={SearchScreen}
                exact
              ></Route>
              <Route
                path="/search/category/:category/name/:name"
                component={SearchScreen}
                exact
              ></Route>
              <Route path="/user/:id" component={UserScreen} exact></Route>
              <Route
                path="/latest-reviews"
                component={LatestReviewsScreen}
                exact
              ></Route>

              <Route
                path="/search/category/:category/name/:name/min/:min/max/:max/rating/:rating/ratingFun/:ratingFun/ratingLearn/:ratingLearn/ratingEmotional/:ratingEmotional/order/:order/language/:language/allTopics/:allTopics/subTopic/:subTopic/rankingRatingMoodDef/:rankingRatingMoodDef/context/:context/level/:level/platform/:platform/tag/:tag/duration/:duration"
                component={SearchScreen}
                exact
              ></Route>
              <Route
                path="/search/allTopics/:allTopics/subTopic/:subTopic"
                component={SearchScreen}
                exact
              ></Route>
              <Route
                path="/search/allTopics/:allTopics"
                component={SearchScreen}
                exact
              ></Route>
              <Route path="/list/:id" component={ListScreen} exact></Route>
              <Route path="/lists" component={ListsScreen} exact></Route>
              <Route
                path="/search/category/:category/name/:name/min/:min/max/:max/rating/:rating/ratingFun/:ratingFun/ratingLearn/:ratingLearn/ratingEmotional/:ratingEmotional/order/:order/language/:language/allTopics/:allTopics/subTopic/:subTopic/rankingRatingMoodDef/:rankingRatingMoodDef/context/:context/level/:level/platform/:platform/tag/:tag/duration/:duration/subscriber/:subscriber"
                component={SearchScreen}
                exact
              ></Route>
              <Route
                path="/search/category/:category/country/:country/name/:name/min/:min/max/:max/rating/:rating/ratingFun/:ratingFun/ratingLearn/:ratingLearn/ratingEmotional/:ratingEmotional/order/:order/language/:language/allTopics/:allTopics/subTopic/:subTopic/rankingRatingMoodDef/:rankingRatingMoodDef/context/:context/level/:level/platform/:platform/tag/:tag/duration/:duration/subscriber/:subscriber"
                component={SearchScreen}
                exact
              ></Route>
              <PrivateRoute
                path="/settings"
                component={SettingsScreen}
              ></PrivateRoute>

              <PrivateRoute
                path="/create-list/:id"
                component={CreateListScreen}
              ></PrivateRoute>
              <PrivateRoute
                path="/create-list"
                component={CreateListScreen}
              ></PrivateRoute>

              <Route path="/" component={HomeScreen} exact></Route>
              <Route path="/modernmba" component={HomeScreen} exact></Route>
              <Route path="/topic/:topic" component={HomeScreen} exact></Route>
              <Route path="/not-found" component={NotFoundPage} />
              <Route path="*" component={NotFoundPage}></Route>
            </Switch>
          </main>
          <footer>
            <div className="space"></div>
            <div className="space"></div>
            <div className="hr-line">
              <hr></hr>
            </div>
            <div className="space"></div>
            <div className="row center">
              <div className="social">
                <a
                  target="_blank"
                  href="https://twitter.com/Favoree_"
                  aria-label="Go to Twitter Favoree Page"
                >
                  <i className="fa-brands fa-twitter"></i>
                </a>
                <a
                  target="_blank"
                  href="https://discord.gg/zfudkGhxzH"
                  aria-label="Go to Favoree's Discord"
                >
                  <i className="fa-brands fa-discord"></i>
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/@favoree-io/featured"
                  aria-label="Go to YouTube Favoree Page"
                >
                  <i class="fa-brands fa-youtube"></i>
                </a>

                <text>contact@favoree.io</text>
              </div>
              <div>
                <a></a>
              </div>

              <div className="textFooter">
                <div>
                  <a href="https://blog.favoree.io/favoree-advertising/">
                    Advertise on Favoree
                  </a>
                  <div className="space"></div>
                  <a href="/add-channel">Add a channel</a>
                </div>
              </div>

              <div className="textFooter">
                <div>
                  <text>©Favoree All Rights Reserved 2024</text>
                </div>

                <div>
                  <text>
                    Made with <i className="fa-solid fa-heart"></i> in France
                  </text>
                </div>
              </div>
            </div>
            <div className="space"></div>

            <div className="space"></div>
            <div className="hr-line">
              <hr></hr>
            </div>
            <div className="space"></div>

            <div className="row center" id="topAlign">
              <div className="leftAlignFlex">
                <div className="listFooter" id="removeMobile">
                  <div>
                    <text>FIND A YOUTUBE VIDEO</text>
                  </div>
                  <div className="space"></div>
                  <li>
                    <a href="/browse-videos">Browse videos</a>
                  </li>
                  <li>
                    <Link to="/">Channel of the week</Link>
                  </li>

                  <li>
                    <a
                      target="_blank"
                      href="https://blog.favoree.io/articles/top-youtube-channels-for-economics-enthusiasts/"
                    >
                      Top economics videos
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://blog.favoree.io/articles/best-minimalist-youtube-channels/"
                    >
                      Top minimalist content
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.youtube.com/watch?v=pB8OWPSBu80"
                    >
                      Most subscribed music artists
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://blog.favoree.io/articles/top-football-youtube-channels/"
                    >
                      Top football YouTube videos
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://blog.favoree.io/articles/best-ways-to-get-historical-data-from-youtube/"
                    >
                      YouTube historical data{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://blog.favoree.io/articles/better-alternatives-to-cocomelon-on-youtube-for-kids-and-toddlers/"
                    >
                      Similar to Cocomelon
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://blog.favoree.io/articles/most-viewed-youtube-videos-of-all-time/"
                    >
                      Most-viewed videos
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://blog.favoree.io/articles/top-richest-youtubers-net-worth/"
                    >
                      Richest YouTubers
                    </a>
                  </li>
                </div>

                <div className="listFooter">
                  <div>
                    <text>DISCOVER THE BEST CHANNELS</text>
                  </div>
                  <div className="space"></div>
                  <li>
                    <Link to="/search/allTopics/Cinema%20and%20TV">
                      Cinema and TV
                    </Link>
                  </li>
                  <li>
                    <a href="https://www.favoree.io/top/best-rated-youtube-channels">
                      Best rated YouTube channels
                    </a>
                  </li>
                  <li>
                    <a href="https://blog.favoree.io/articles/worst-rated-youtubers/">
                      Worst rated channels
                    </a>
                  </li>
                  <li>
                    <Link to="/latest-reviews">Latest reviews</Link>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://blog.favoree.io/articles/best-youtube-channels-for-movie-reviews/"
                    >
                      Best channels for movie reviews
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://blog.favoree.io/articles/most-subscribed-music-artists-on-youtube/"
                    >
                      Most subscribed music channels
                    </a>
                  </li>
                  <li>
                    <a href="https://www.favoree.io/top/best-rated-gaming-youtube-channels">
                      Best Rated Gaming channels
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://blog.favoree.io/2023/04/24/5-ways-to-discover-new-youtube-channels/"
                    >
                      Ways to find new YouTube channels
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://blog.favoree.io/articles/most-subscribed-youtubers-world/"
                    >
                      Most subscribed YouTubers
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://blog.favoree.io/articles/most-subscribed-minecraft-youtubers/"
                    >
                      Top Minecraft YouTubers
                    </a>
                  </li>
                  <li>
                    <a href="https://www.favoree.io/top/best-rated-sport-youtube-channels">
                      Top rated sports channels
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://blog.favoree.io/articles/youtube-statistics-and-insights/"
                    >
                      YouTube statistics
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://blog.favoree.io/articles/how-to-run-an-influencer-marketing-campaign-on-youtube/"
                    >
                      Marketing on YouTube
                    </a>
                  </li>
                </div>
                <div className="listFooter">
                  <div>
                    <text>WHAT IS FAVOREE?</text>
                  </div>
                  <div className="space"></div>
                  <li>
                    <a>
                      Favoree is a new way to discover YouTube where you can
                      explore and review channels. Find quality creators based
                      on your interests.
                    </a>
                    <div className="space"></div>
                    <a
                      target="_blank"
                      href="https://blog.favoree.io/what-is-favoree/"
                    >
                      <i className="fa-solid fa-arrow-right"></i> More about
                      Favoree
                    </a>
                    <div className="space"></div>

                    <a target="_blank" href="https://blog.favoree.io/">
                      <i className="fa-solid fa-arrow-right"></i> Visit our blog
                    </a>
                    <div className="space"></div>

                    <i className="fa-solid fa-arrow-right"></i>
                    <Link to="/contact"> Contact us</Link>
                    <div className="space"></div>
                    <a
                      target="_blank"
                      href="https://blog.favoree.io/articles/best-youtube-crawlers/"
                    >
                      <i className="fa-solid fa-arrow-right"></i> Best YouTube
                      channel crawlers
                    </a>
                    <div className="space"></div>

                    <a
                      target="_blank"
                      href="https://blog.favoree.io/articles/category/top-channels/"
                    >
                      <i className="fa-solid fa-arrow-right"></i> Lists of the
                      best channels
                    </a>
                    <div className="space"></div>

                    <a
                      target="_blank"
                      href="https://blog.favoree.io/privacy-policy/"
                    >
                      <i className="fa-solid fa-arrow-right"></i> Privacy Policy
                    </a>
                    <div className="space"></div>

                    <a
                      target="_blank"
                      href="https://blog.favoree.io/terms-of-service/"
                    >
                      <i className="fa-solid fa-arrow-right"></i> Terms of
                      Service
                    </a>
                    <div className="space"></div>

                    <a
                      target="_blank"
                      href="https://blog.favoree.io/articles/best-youtube-analytics-tools/"
                    >
                      <i className="fa-solid fa-arrow-right"></i> Best YouTube
                      analytics tools
                    </a>
                  </li>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;

/* 
              <AdminRoute
                path="/userlist"
                component={UserListScreen}
              ></AdminRoute>

              */
