import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listProductNamesQuery,
  listProductsQuery,
  listVideosQuery,
} from "../actions/productActions";
import { listUsersQuery } from "../actions/userActions";
import { useMediaQuery } from "react-responsive";
import Product from "../components/Product";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { Link, useLocation } from "../../node_modules/react-router-dom/index";
import moment from "../../node_modules/moment/moment";
import Helmet from "../../node_modules/react-helmet/lib/Helmet";

export default function SearchResultsScreen() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let location = useLocation();

  const dispatch = useDispatch();

  const [query, setQuery] = useState(location.pathname.split("results/")[1]);

  useEffect(() => {
    setQuery(location.pathname.split("results/")[1]);

    const queryResult = location.pathname.split("results/")[1];

    if (queryResult.length > 2) {
      dispatch(listProductNamesQuery(queryResult));

      dispatch(listProductsQuery(queryResult));
      dispatch(listVideosQuery(queryResult));
      dispatch(listUsersQuery(queryResult));
    }
    window.scrollTo(0, 0);
  }, [location]);

  const productNamesListQuery = useSelector(
    (state) => state.productNamesListQuery
  );
  const { loadingNameQuery, errorNameQuery, productNamesQuery } =
    productNamesListQuery;

  const productListQuery = useSelector((state) => state.productListQuery);
  const { loadingQuery, errorQuery, productsQuery } = productListQuery;

  const videoListQuery = useSelector((state) => state.videoListQuery);
  const { loadingVideoQuery, errorVideoQuery, videosQuery } = videoListQuery;

  const userListQuery = useSelector((state) => state.userListQuery);
  const { loadingUserQuery, errorUserQuery, usersQuery } = userListQuery;

  const isMobile = !useMediaQuery({
    query: "(min-width: 980px)",
  });

  return (
    <div>
      <Helmet>
        <title>Find your favorite channel | Favoree</title>
        <meta
          name="description"
          content="Searching for quality content? Here’s a complete list of the best YouTube channels. Sort by popularity, topic, rating, duration and more"
        ></meta>

        <link rel="canonical" href="https://www.favoree.io/search"></link>
        <link
          rel="keywords"
          content="Favoree, YouTube, Channel, Discover, Find, List, Filter, Creator, YouTuber, Content, Rate, Review"
        ></link>
      </Helmet>
      <div className="cover" id="scdColor">
        {query.length > 2 ? (
          <div>
            <h2>
              {" "}
              <i className="fa-solid fa-magnifying-glass"></i> Search results
              for "{query.replace("%20", " ")}"
            </h2>
            {(productNamesQuery && productNamesQuery.length > 0) ||
            (productsQuery && productsQuery.length > 0) ||
            (videosQuery && videosQuery[0]) ? (
              <div>
                <strong>Show results for:</strong>
                <div className="space"> </div>
                <div
                  className="filterSlider"
                  style={{
                    "margin-left": "-4rem",
                    "max-width": "calc(100vw + 2rem)",
                  }}
                >
                  <ul className="ulFilter">
                    {productNamesQuery && productNamesQuery.length > 0 ? (
                      <button
                        class="sortVideos"
                        id="other"
                        onClick={() => {
                          // setResultSelected("channelNames");

                          document
                            .getElementById("channelNames")
                            .scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                            });
                        }}
                      >
                        <p>Channel names</p>
                      </button>
                    ) : (
                      <></>
                    )}
                    {productsQuery && productsQuery.length > 0 ? (
                      <button
                        className="sortVideos"
                        id="other"
                        onClick={() => {
                          // setResultSelected("relatedChannels");

                          document
                            .getElementById("relatedChannels")
                            .scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                            });
                        }}
                      >
                        <p>Related channels</p>
                      </button>
                    ) : (
                      <></>
                    )}

                    {videosQuery && videosQuery[0] ? (
                      <button
                        className="sortVideos"
                        id="other"
                        onClick={() => {
                          // setResultSelected("relatedVideos");

                          document
                            .getElementById("relatedVideos")
                            .scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                            });
                        }}
                      >
                        <p>Related videos</p>
                      </button>
                    ) : (
                      <></>
                    )}
                    {usersQuery && usersQuery[0] ? (
                      <button
                        className="sortVideos"
                        id="other"
                        onClick={() => {
                          // setResultSelected("relatedVideos");

                          document
                            .getElementById("relatedUsers")
                            .scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                            });
                        }}
                      >
                        <p>User names</p>
                      </button>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <h2>
            {" "}
            <i className="fa-solid fa-triangle-exclamation"></i> You need to
            enter at least 3 letters to launch the search
          </h2>
        )}
      </div>
      <div className="mainElement">
        {query.length > 2 && (
          <div>
            {loadingNameQuery ? (
              <div>
                <div className="space"> </div>
                <LoadingBox></LoadingBox>
              </div>
            ) : errorNameQuery ? (
              <MessageBox variant="danger">{errorNameQuery}</MessageBox>
            ) : productNamesQuery.length > 0 ? (
              <>
                <h2 id="channelNames">By channel names</h2>

                <div className="row center">
                  {productNamesQuery.map((product) => (
                    <Product key={product._id} product={product}></Product>
                  ))}
                </div>
                <div className="space"> </div>
              </>
            ) : (
              <div> </div>
            )}
            {(productsQuery && productsQuery.length > 0) ||
            (productNamesQuery && productNamesQuery.length < 1) ? (
              <h2 id="relatedChannels">Most related channels</h2>
            ) : (
              <div> </div>
            )}
            {loadingQuery ? (
              loadingNameQuery ? (
                <div></div>
              ) : (
                <LoadingBox></LoadingBox>
              )
            ) : errorQuery ? (
              <MessageBox variant="danger">{errorQuery}</MessageBox>
            ) : (
              <>
                {productsQuery.length === 0 &&
                productNamesQuery &&
                productNamesQuery.length < 1 ? (
                  <div>
                    <MessageBox>
                      No results for now{" "}
                      <i className="fa-regular fa-face-frown"></i>. More content
                      coming soon!{" "}
                    </MessageBox>
                    <div className="space"></div>
                    <a href="/add-channel">
                      <button class="primary"> Suggest a new channel </button>{" "}
                    </a>
                    <div className="space"></div>
                  </div>
                ) : (
                  <div> </div>
                )}
                <div className="row center">
                  {productsQuery.map((product) => (
                    <Product key={product._id} product={product}></Product>
                  ))}
                </div>
                <div className="space"> </div>
              </>
            )}
          </div>
        )}
      </div>
      {query.length > 2 && (
        <div className="mainElement">
          {loadingVideoQuery ? (
            loadingQuery && loadingNameQuery ? (
              <div> </div>
            ) : (
              <div>
                <div className="space"> </div>
                <div className="space"> </div>

                <LoadingBox></LoadingBox>
              </div>
            )
          ) : errorVideoQuery ? (
            <MessageBox variant="danger">{errorVideoQuery}</MessageBox>
          ) : (
            <div>
              {videosQuery[0] && (
                <h2 id="relatedVideos">Most related videos</h2>
              )}
              <div className="row center" id="videosInSearch">
                {videosQuery.map((c) => (
                  <a
                    href={
                      "https://www.youtube.com/watch?v=" + c.lastVideos.videoId
                    }
                    target="_blank"
                    style={{
                      width: "100%",
                      "max-width": "324px",
                      margin: "2rem",
                    }}
                  >
                    <div className="videoThumbnailsBrowse">
                      <div className="alignVertical">
                        <div className="thumbnailContainer">
                          <a className="durationInfo">
                            {c.lastVideos.duration}min
                          </a>
                          <img
                            src={c.lastVideos.thumbnail}
                            className="browseMedium"
                            alt={c.lastVideos.title + " video thumbnail"}
                          />
                          <i className="fa-solid fa-play" id="toPlay"></i>
                        </div>
                        <a id="moreSpaceUp">
                          {c.lastVideos.title
                            .split(/\s+/)
                            .slice(0, 15)
                            .join(" ")}
                          {c.lastVideos.title.match(/(\w+)/g) &&
                          c.lastVideos.title.match(/(\w+)/g).length > 15
                            ? "..."
                            : ""}
                        </a>

                        <Link
                          to={`/channel/${c.channelName
                            .replaceAll(" ", "_")
                            .replaceAll("-", "_")
                            .toLowerCase()}-${c._id}`}
                        >
                          <text>{c.channelName}</text> <text>-</text>{" "}
                          <text>
                            {c.lastVideos.nbViews > 1000000
                              ? Math.round(c.lastVideos.nbViews / 100000) / 10 +
                                "M"
                              : c.lastVideos.nbViews > 10000
                              ? Math.round(c.lastVideos.nbViews / 1000) + "K"
                              : c.lastVideos.nbViews > 1000
                              ? Math.round(c.lastVideos.nbViews / 100) / 10 +
                                "K"
                              : c.lastVideos.nbViews}{" "}
                            views
                          </text>{" "}
                          <text>-</text>{" "}
                          <text>
                            {moment(c.lastVideos.publishDate).fromNow()}
                          </text>
                        </Link>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
              <div className="space"> </div>
              <div className="space"> </div>
            </div>
          )}
        </div>
      )}
      {query.length > 2 && (
        <div className="mainElement">
          {loadingUserQuery ? (
            <div>
              <div className="space"> </div>

              <div className="space"> </div>

              <LoadingBox></LoadingBox>
            </div>
          ) : errorUserQuery ? (
            <MessageBox variant="danger">{errorUserQuery}</MessageBox>
          ) : (
            <div>
              {usersQuery[0] && <h2 id="relatedUsers">Favoree users</h2>}
              <div className="row center" id="userListing">
                <ul className="alignVertical">
                  {usersQuery.map((c) => (
                    <i>
                      <a href={"/user/" + c._id}>
                        <i class="fa-solid fa-user"></i> {c.name}
                      </a>
                    </i>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      )}

      <div className="space"></div>
      <div className="space"></div>
      <div className="space"></div>

      <div className="cover" id="thrdColor">
        <div className="coverInside">
          <div className="twoThird">
            <h4>Can't find your favorite channel?</h4>
            <div className="space"></div>

            <a className="buttonLightGrey" id="darkBlue" href="/add-channel">
              {" "}
              Add new channel
            </a>
          </div>
          {!isMobile && (
            <div className="oneThird">
              <img
                className="headerPicture"
                src="/images/addChannel.png"
                alt="add a Channel"
              ></img>
            </div>
          )}
        </div>
      </div>
      <div className="space"></div>

      <div className="space"></div>
      <div className="space"></div>
    </div>
  );
}
