import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import axios from "../../node_modules/axios/index";
import Reviews from "../components/Reviews";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../AuthContext";
import LoadingBox from "../components/LoadingBox";
import Helmet from "../../node_modules/react-helmet/lib/Helmet";
import UserPicture from "../components/UserPicture";
import CreatorList from "../components/CreatorList";

export default function SearchScreen(props) {
  const dispatch = useDispatch();

  const userId = props.match.params.id;

  const [reviewList, setReviewList] = useState("");
  const [userReviewList, setUserReviewList] = useState("");

  const [userSelected, setUserSelected] = useState("");
  const [toggle, setToggle] = useState("ReviewsAndRating");
  /* const [reviewsLikedIds, setReviewsLikedIds] = useState("");  */
  const [creatorLists, setCreatorLists] = useState("");

  const isMobile = !useMediaQuery({
    query: "(min-width: 952px)",
  });

  useEffect(() => {
    // When a user click on another user in the reviews liked section, we want to send him to the user page in the Reviews and Rating section, not stay in the "Liked reviews" section
    setToggle("ReviewsAndRating");
    window.scrollTo(0, 0);
  }, [userId]);

  const { currentUser } = useAuth();

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  useEffect(() => {
    async function getReviews(x) {
      let response = await axios.get(
        process.env.REACT_APP_API_ADDRESS +
          `reviews/getreviews/id/all/user/${userId}/order/latest/type/all/moderation/false`
      );
      const reviewListData = response.data;
      setUserReviewList(reviewListData);
      setReviewList(reviewListData);
    }

    async function getUser(x) {
      let response = await axios.get(
        process.env.REACT_APP_API_ADDRESS + `users/user/${userId}`
      );
      const user = response.data;
      setUserSelected(user);
    }

    getReviews();

    getUser(userId);
  }, [userId, dispatch]);

  async function getReviews(x) {
    //getting the liked reviews of the user

    let response = await axios.get(
      process.env.REACT_APP_API_ADDRESS +
        `reviews/getreviews/id/${x}/user/all/order/latest/type/all/moderation/false`
    );
    const reviewListData = response.data;
    setReviewList(reviewListData);
  }
  async function getLikedReviewsIds() {
    // getting the likes of the user

    let response = await axios.get(
      process.env.REACT_APP_API_ADDRESS + `likeReviews/uid/${userSelected.uid}`
    );
    const reviewsliked = Object.values(response.data)
      .map((item) => item.reviewId)
      .join("_");
    if (reviewsliked) {
      getReviews(reviewsliked);
    } else {
      setReviewList("");
    }
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    const token = currentUser?.auth.currentUser.accessToken || "";

    const uid = userSelected?.uid;

    const fetchData = async () => {
      const data = {
        token,
        uid,
      };
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_ADDRESS +
            "creatorlists/getcreatorlists/user",
          data
        );

        setCreatorLists(response.data);

        return response.data; // Return the fetched data
      } catch (error) {
        console.error(
          "Error fetching creator lists:",
          error.response ? error.response.data : error.message
        );

        throw error; // Rethrow or handle error as needed
      }
    };

    if (userSelected?.uid) {
      fetchData();
    }
  }, [userSelected]);

  return (
    <div className="profileUser">
      <Helmet>
        <title>
          {userSelected
            ? userSelected.userName + "'s profile | Favoree"
            : "User profile | Favoree"}
        </title>
        <meta
          name="description"
          content={
            userSelected && userReviewList
              ? userSelected.userName +
                " uses Favoree to share channel reviews and ratings. " +
                userReviewList.length +
                " channels reviewed. Member since " +
                userSelected.creationDate.substring(0, 4) +
                "."
              : "Channel reviews written by this user."
          }
        ></meta>
        <link
          rel="canonical"
          href={"https://www.favoree.io" + props.match.url}
        ></link>
      </Helmet>
      {!userSelected ? (
        <div className="mainElement">
          {" "}
          <div className="space"></div>
          <div className="space"></div>
          <div className="space"></div>
          <div className="space"></div>
          <LoadingBox></LoadingBox>
        </div>
      ) : (
        <div>
          <div className="space"></div>
          <div className="space"></div>
          <div className="space"></div>
          <div className="space"></div>
          <div className="mainElement">
            <div className="row top" id="coverReviews">
              <div className="alignHorizontal" id="profile">
                <div className="space"></div>
                <div className="space"></div>
                <div className="space"> </div>
                <UserPicture
                  uid={userSelected?.uid}
                  imageSize="avatarPictureMedium"
                ></UserPicture>
                <div className="space"></div>
                <div className="space"></div>
                <div className="space"> </div>

                <div className="verticalAlign" id="profileDescription">
                  <div className="alignHorizontal" id="profile">
                    <h2 id="italic">{userSelected.userName}</h2>{" "}
                    {userId && user && userId == user.userId ? (
                      <a href="/settings" className="editButton">
                        {" "}
                        <button id="transparentButton">
                          <i className="fa-solid fa-pen-to-square"></i> Edit
                          profile
                        </button>
                      </a>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div className="space"> </div>
                  <div className="space"> </div>
                  {userSelected.description &&
                  userSelected.description !== " " ? (
                    <div>
                      <p>{userSelected.description}</p>
                    </div>
                  ) : (
                    <div> </div>
                  )}
                  <div className="alignHorizontal" id="profile">
                    {userSelected.youtube && (
                      <a
                        target="_blank"
                        href={"https://www.youtube.com/" + userSelected.youtube}
                      >
                        <strong className="youTubeColor">
                          <i className="fa-brands fa-youtube"></i>
                          {" " + userSelected.youtube.substring(1)}
                        </strong>
                      </a>
                    )}
                    {userSelected.youtube && (
                      <div>
                        {" "}
                        <div className="space"></div>
                        <div className="space"></div>{" "}
                      </div>
                    )}

                    {userSelected.twitter && (
                      <a
                        target="_blank"
                        href={
                          "https://twitter.com/" +
                          userSelected.twitter.replace("@", "")
                        }
                      >
                        <strong className="twitterColor">
                          <i className="fa-brands fa-twitter"></i>
                          {" " + userSelected.twitter.substring(1)}
                        </strong>
                      </a>
                    )}
                  </div>
                </div>
              </div>

              <div className="alignVertical" id="userInfo">
                {userReviewList && (
                  <div className="alignHorizontal" id="alignText">
                    <h3>{userReviewList.length} </h3>{" "}
                    <div className="space"></div>{" "}
                    <text> review{userReviewList.length < 1 ? "" : "s"} </text>
                  </div>
                )}
                {userReviewList && userReviewList.length > 0 && (
                  <div className="alignHorizontal" id="alignText">
                    <h3>
                      {(
                        userReviewList
                          .map((review) => review.rating)
                          .reduce((prev, next) => prev + next) /
                        userReviewList.length
                      ).toFixed(1)}{" "}
                    </h3>
                    <i className="fa-solid fa-star"></i>
                    <div className="space"></div>
                    <text> average rating </text>
                  </div>
                )}
                {userReviewList && (
                  <div className="alignHorizontal" id="alignText">
                    <h3>
                      {userSelected.creationDate &&
                        monthNames[
                          userSelected.creationDate.substring(5, 7) - 1
                        ]}{" "}
                      {userSelected.creationDate &&
                        userSelected.creationDate.substring(0, 4)}
                    </h3>{" "}
                    <div className="space"></div>
                    <text> member since</text>{" "}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="space"></div>
          <div className="space"></div>

          <div className="space"></div>

          <div className="hr-line">
            <hr></hr>
          </div>
          <div className="space"></div>
          <div className="space"></div>
          <div className="space"></div>

          <div className="mainElement">
            {userReviewList && (
              <div className="alignHorizontal" id="toRight">
                <div className="filterSlider">
                  <ul class="ulFilter">
                    {" "}
                    <button
                      className="sortVideos"
                      id={
                        toggle == "ReviewsAndRating" ? "sortVideosOn" : "other"
                      }
                      onClick={() => {
                        setToggle("ReviewsAndRating");
                        setReviewList(userReviewList);
                      }}
                    >
                      <p>
                        {" "}
                        <i className="fa-solid fa-star"></i> All ratings{" "}
                      </p>
                    </button>
                    {userReviewList.length > 9 && (
                      <button
                        className="sortVideos"
                        id={toggle == "ReviewsOnly" ? "sortVideosOn" : "other"}
                        onClick={() => {
                          setToggle("ReviewsOnly");
                          setReviewList(
                            userReviewList.filter(
                              (review) => review.comment.length >= 1
                            )
                          );
                        }}
                      >
                        <p>
                          <i class="fa-solid fa-comments"></i> Reviews only{" "}
                        </p>
                      </button>
                    )}
                    <button
                      className="sortVideos"
                      id={toggle == "creatorList" ? "sortVideosOn" : "other"}
                      onClick={() => {
                        setToggle("creatorList");
                      }}
                    >
                      <p>
                        {" "}
                        <i class="fa-solid fa-list-ol"></i> Channel lists{" "}
                      </p>
                    </button>
                    <button
                      className="sortVideos"
                      id={
                        toggle == "OtherUsersReviews" ? "sortVideosOn" : "other"
                      }
                      onClick={() => {
                        setToggle("OtherUsersReviews");
                        getLikedReviewsIds();
                      }}
                    >
                      <p>
                        {" "}
                        <i className="fa-solid fa-heart"></i> Liked reviews{" "}
                      </p>
                    </button>
                  </ul>
                </div>
              </div>
            )}

            {reviewList.length <= 9 && (
              <div>
                {" "}
                <div className="space"> </div> <div className="space"> </div>
              </div>
            )}
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>

            <div className="row top" id="coverReviews">
              {reviewList &&
              reviewList.length > 0 &&
              toggle !== "creatorList" ? (
                <Reviews
                  reviewList={reviewList}
                  currentUser={currentUser}
                  reviewRemoval={true}
                ></Reviews>
              ) : toggle !== "creatorList" ? (
                <div id="leftAlign">
                  <text>
                    {toggle == "OtherUsersReviews"
                      ? "This user hasn't liked any review for now"
                      : "This user hasn't reviewed a channel for now"}
                  </text>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                </div>
              ) : (
                <div></div>
              )}
            </div>

            {creatorLists?.length > 0 && toggle == "creatorList" ? (
              <div className="row top">
                {creatorLists.map((c) => (
                  <div id={c._id}>
                    <CreatorList
                      name={c.listName}
                      list={c.list}
                      description={c.description}
                      uid={c.uid}
                      _id={c._id}
                    ></CreatorList>
                  </div>
                ))}
              </div>
            ) : toggle == "creatorList" ? (
              <div className="row top" id="coverReviews">
                <div id="leftAlign">
                  <text>
                    This user has not created any YouTube channel list
                  </text>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                  <div className="space"></div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            <div className="space"></div>
            <div className="space"></div>
          </div>
        </div>
      )}
      <div className="space"></div>
      <div className="space"></div>
      <div className="space"></div>
      <div className="space"></div>
      <div className="space"></div>
      <div className="space"></div>

      <div className="cover" id="thrdColor">
        <div className="coverInside">
          <div className="twoThird">
            <h4>Can't find your favorite channel?</h4>
            <div className="space"></div>

            <a className="buttonLightGrey" id="darkBlue" href="/add-channel">
              {" "}
              Add new channel
            </a>
          </div>
          {!isMobile && (
            <div className="oneThird">
              <img
                className="headerPicture"
                src="/images/addChannel.png"
                alt="add a Channel"
              ></img>
            </div>
          )}
        </div>
      </div>
      <div className="space"></div>
      <div className="space"></div>
      <div className="space"></div>
    </div>
  );
}
