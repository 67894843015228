import React, { useEffect, useState } from "react";
import axios from "../../node_modules/axios/index";
import UserPicture from "../components/UserPicture";
import { Link } from "react-router-dom";

export default function CreatorList(props) {
  const { name, list, uid, description, _id } = props;

  const [channels, setChannels] = useState("");
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");

  const creatorList = list.map(({ channelId }) => channelId).join(",");

  useEffect(() => {
    async function getChannels() {
      let responseRanking = await axios.get(
        process.env.REACT_APP_API_ADDRESS +
          `products/channelsforlists/${creatorList}/getvideos/false`
      );
      setChannels(responseRanking.data);
    }

    getChannels();
  }, [list]);

  useEffect(() => {
    async function getUser(x) {
      let response = await axios.get(
        process.env.REACT_APP_API_ADDRESS + `users/uid/${uid}`
      );

      const userName = response.data[0].name;
      const userId = response.data[0]._id;

      setUserName(userName);
      setUserId(userId);
    }
    getUser();
  }, [list]);

  return (
    <div className="creatorListItem" id={_id}>
      <div className="alignVertical">
        <div class="image-container">
          {channels?.length > 0
            ? channels.map((c) => (
                <img className="smallRound" src={c.profileImage}></img>
              ))
            : [0, 1, 2, 3, 4, 5, 6, 7].map((c) => (
                <div
                  className="smallRound"
                  style={{
                    "background-color": "grey",
                    display: "inline-block",
                    "margin-left": "5px",
                    "margin-top": "0rem",
                  }}
                ></div>
              ))}
          {channels?.length > 3 && <div class="image-fade"></div>}
        </div>
        <Link to={`/list/${_id}`.toLowerCase()}>
          <h2>{name}</h2>
        </Link>
        <div className="space"></div>

        <div className="alignHorizontal">
          <a href={"/user/" + userId}>
            <UserPicture uid={uid} imageSize="reallySmallRound"></UserPicture>
          </a>

          <text
            id="grey"
            style={{
              "margin-left": "1rem",
              "margin-right": "0.5rem",
            }}
          >
            {"by " + " "}{" "}
          </text>
          <a href={"/user/" + userId}>
            <strong id="italic"> {userName}</strong>
          </a>
          <text
            id="grey"
            style={{
              "margin-left": "1rem",
            }}
          >
            {channels?.length} channels
          </text>
        </div>

        <div className="space"></div>

        <text>{description}</text>
      </div>
    </div>
  );
}
