import React, { useState } from "react";

export default function StarRating(props) {
  const [giveRating, setGiveRating] = useState(null);

  props.passChildData(giveRating);

  const [hover, setHover] = useState(0);

  const handleRating = (value) => {
    setGiveRating(value);
  };

  const handleMouseEnter = (value) => {
    setHover(value);
  };

  const handleMouseLeave = () => {
    setHover(null);
  };

  return (
    <div className="star-rating">
      {[...Array(5)].map((_, index) => {
        const value = index + 1;
        const halfValue = index + 0.5;
        return (
          <span
            key={index}
            style={{
              position: "relative",
              display: "inline-block",
              width: "24px",
            }}
          >
            <i
              className={`fa-solid fa-star`}
              style={{
                color: value <= (hover || giveRating) ? "red" : "grey",
                cursor: "pointer",
                position: "absolute",
                left: 0,
                top: 0,
              }}
              onClick={() => handleRating(value)}
              onMouseEnter={() => {
                handleMouseEnter(value);
                setHover(value);
              }}
              onMouseLeave={handleMouseLeave}
            ></i>
            <div className="differentSize">
              <i
                className={`fa-solid fa-star-half`}
                style={{
                  color: halfValue <= (hover || giveRating) ? "red" : "grey",
                  cursor: "pointer",
                  position: "absolute",
                  left: 0,
                  top: 0,
                  zIndex: halfValue <= (hover || giveRating) ? 1 : 0,
                }}
                onClick={() => handleRating(halfValue)}
                onMouseEnter={() => {
                  handleMouseEnter(halfValue);
                  setHover(halfValue);
                }}
                onMouseLeave={handleMouseLeave}
              ></i>
            </div>
          </span>
        );
      })}
      <p>
        {" "}
        {hover === 0.5
          ? "Terrible"
          : hover === 1
          ? "Mediocre"
          : hover === 1.5
          ? "Bad"
          : hover === 2
          ? "Watchable"
          : hover === 2.5
          ? "Average"
          : hover === 3
          ? "Good"
          : hover === 3.5
          ? "Very good"
          : hover === 4
          ? "Great"
          : hover === 4.5
          ? "Excellent"
          : hover === 5
          ? "Perfect"
          : ""}
      </p>
    </div>
  );
}
